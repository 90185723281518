html {
    font-size: 10px;
    scroll-behavior: auto !important
}

body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6rem;
    background-color: #FAFBFC;
    color: #112211;
    line-height: 1;
}

body>iframe {
    display: none;
}
.visibilityHide {
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
  font-size: 0;
  margin: 0;
  padding: 0;
  line-height: 0;
  position: absolute;
  z-index: -1;
  top:0;
  left: 0;
}
html.lenis, html.lenis body {
    height: auto;
  }
  
  .lenis.lenis-smooth {
    scroll-behavior: auto !important;
  }
  
  .lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
  }
  
  .lenis.lenis-stopped {
    overflow: hidden;
  }
  
  .lenis.lenis-smooth iframe {
    pointer-events: none;
  }

  html::-webkit-scrollbar, .custom-scroll-bar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
html::-webkit-scrollbar-track, .custom-scroll-bar::-webkit-scrollbar-track {
    background: transparent;
}
html::-webkit-scrollbar-thumb, .custom-scroll-bar::-webkit-scrollbar-thumb {
    background: #ad926f;
}