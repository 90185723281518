footer {
    background-color: #AD926F;
    padding: 10rem 0 0;

    .navbar-brand{
        background-image: url("../../styles/images/Al-Raihann-logo-footer.svg");
    }

    P {
        font-size: 1.2rem;
        line-height: 1.8rem;
        font-weight: 700;
        margin-top: 1.4rem;
        color: #fff;
        padding-right: 10rem;
    }

    h2 {
        color: #fff;
        font-size: 1.5rem;
        line-height: 2.3rem;
        font-weight: 700;
        margin: 3.5rem 0 2rem;
    }

    .nav {

        a {
            color: #fff;
            font-size: 1.2rem;
            line-height: 1.8rem;
            font-weight: 700;
            padding: 0;
            margin: 0 0 0.5rem 0;
        }
    }

    .subscribeSection {
        background-color: #fff;
        border-radius: 0.5rem;
        padding: 0.5rem;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;

        .form-control {
            border: 0;
            flex: 1 1 auto;
            width: 1%;
            font-size: 1.2rem;
            line-height: 1.8rem;
            font-weight: 500;
            color: #00000080;

            &:focus {
                box-shadow: none;
            }

            &::placeholder {
                color: #00000080;
            }
        }
    }

    .footnote {
        border-top: 0.1rem solid #D9D9D980;
        padding: 5rem 0;
        font-weight: 700;
        color: #fff;
        font-size: 1.5rem;
        line-height: 2.3rem;
        margin: 5rem 0 0;
    }

}
    .policymodal{

        .modal-dialog{
            max-width: 100%;
             height: 96%;
            padding:1rem;
            .modal-content{
                height: 100%;
            }
        }
    }
/**Responsive css**/
@media (768px <= width < 990px) {
    footer{

  }
}

/**Mobile**/
@media ( width < 768px) {
    footer{
        padding: 3rem 0 6.5rem;
        .navbar-brand{
            display: block;
            margin: 2rem 0;
        }
        .nav{
            a{
                padding: 0.8rem 0;
            }
        }
p{
    padding-right: 0;
}
h2{margin: 2rem 0;}
.footnote{
    padding: 2rem 0 ;
    margin: 2rem 0 0;
}
    }
}
/**Responsive css ends**/