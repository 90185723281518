/****/
.semiBold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.goldColor {
  color: #ad926f;
}
.green {
  color: #279051 !important;
}
.red {
  color: #8b0000 !important;
}
.divider {
  margin: 6.4rem 0;
  opacity: 1;
  border-top: 0.1rem solid #d9d9d980;
}
.show-mobile {
  display: none !important;
}
.iconLegends {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.1rem;
  img {
    max-width: 1.5rem;
    margin: 0 0.5rem 0 0;
    vertical-align: middle;
  }
}
.viewMoreBtn {
  font-weight: 500;
  font-size: 1.4rem;
  color: #ad926f;
  text-decoration: none;
}
/****/

.navbar-brand {
  width: 12.6rem;
  height: 6.5rem;
  background-image: url(../../styles/images/Al-Raihann-logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  text-indent: -9999px;
  margin: 0;
}

/*Button css*/
.btn-primary {
  background-color: #ad926f;
  font-size: 1.3rem;
  line-height: 2rem;
  border-radius: 0.4rem;
  border: 0;
  font-weight: 600;
  color: #112211;
  padding: 1.5rem;

  &:hover {
    background-color: #5f2146;
    color: #fff !important;
  }
  &:focus {
    background-color: #7b4866 !important;
    color: #fff !important;
    border: 0.1rem solid #cac4d0;
  }
  &:active {
    background-color: #d4a6c2 !important;
    color: #fff !important;
  }

  &:disabled {
    background-color: #d2d1d3;
    color: #8f8c91;
  }
}

.btn-outline-primary {
  border: 0.1rem solid #ad926f;
  color: #112211;
  padding: 1.5rem;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 600;

  &:hover {
    border: 0.1rem solid #5f2146;
    background-color: transparent;
    color: #ad926f;
  }
  &:active {
    border: 0.1rem solid #5f2146 !important;
    background-color: transparent !important;
    color: #ad926f !important;
  }

  &:disabled {
    border: 0.1rem solid #d2d1d3;
    color: #8f8c91;
  }
}

.btn-favorite {
  border: 0.1rem solid #ad926f;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 0.4rem;
  background-image: url("../../styles/images/icons/feather-heart.svg");
  background-position: center center;
  background-repeat: no-repeat;
  text-indent: -9999px;

  &:hover {
    border: 0.1rem solid #ad926f;
  }
}
.amenitiesBtn {
  display: none;
}
/*Button css ends*/

/**/
.dateField {
  .form-control {
    background-image: url("../images/icons/datetimepicker.svg");
    background-position: 95% center;
    background-size: 1.8rem 2.1rem;
    background-repeat: no-repeat;
    background-color: transparent;

    &:disabled{
      background-color: #e9ecef;
    }
  }
}

.timeField {
  .form-control {
    background-image: url("../images/icons/timepicker.svg");
    background-position: 95% center;
    background-size: 1.8rem 2.1rem;
    background-repeat: no-repeat;
    background-color: transparent;

    &:disabled{
      background-color: #e9ecef;
    }
  }

  .react-datepicker-popper{
    width: 100%;

    .react-datepicker{
      width: 100%;

      .react-datepicker__input-time-container{
        margin:5px;
        text-align: center;
      }

      .react-datepicker-time__caption{display: none;}
      .react-datepicker-time__input{
margin:0;
.react-datepicker-time__input{font-size: 1.5rem;}
      }
      
    }
  }
}

.form-control {
  border: 0.1rem solid #79747e;
  height: 5.6rem;
  color: #1c1b1f;
  font-size: 1.4rem;
  line-height: 2.1rem;
  padding: 1.5rem;

  &:focus {
    box-shadow: none;
    border: 0.1rem solid #5f2146;
  }

  &::placeholder {
    color: #1c1b1f;
  }

  &.error {
    border: 0.1rem solid #fd736e;
    color: #fd736e;
  }
}
.fieldContainer {
  background-color: #fff;
  border-radius: 1.6rem;
  padding: 3.2rem;
  .formField {
    position: relative;

    label {
      position: absolute;
      top: -0.8rem;
      left: 1.5rem;
      background-color: #fff;
      color: #112211;
      font-size: 1.2rem;
      margin: 0;
      z-index: 1;
    }

    select {
      cursor: pointer;
    }
    .dropdown {
      width: 100%;
      .dropdown-toggle {
        border: 0.1rem solid #79747e;
        padding: 1.5rem;
        height: 5.6rem;
        border-radius: 0.375rem;
        background-color: transparent;
        color: #1c1b1f;
        font-weight: normal;
        width: 100%;
        text-align: left;

        &:hover,
        &:focus,
        &:active {
          background-color: transparent !important;
          color: #1c1b1f !important;
        }
      }

      .dropdown-menu {
        padding: 1rem;
        width: 100%;
      }
      span {
        font-size: 1.4rem;
        margin-right: 1rem;
        font-weight: 500;
      }
    }

    .counter {
      .form-control {
        width: 3.5rem;
        height: 3.5rem;
        margin: 0 1rem;
        padding: 0.5rem;
        text-align: center;
      }
      .btn-outline-primary {
        width: 3.5rem;
        height: 3.5rem;
        padding: 0.5rem;
        font-size: 2rem;
        font-weight: normal;
      }
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;
}
/**/
.rating {
  .star {
    font-weight: normal;
    font-size: 2.4rem;
    line-height: 1;
    margin: 0 0.2rem;

    &.on {
      color: #ffce31;
    }
  }
}
/**/
.graphicalBg {
  background-image: url(../images/texture.svg);
}

.iconHeading {
  color: #000000;
  font-size: 4.5rem;
  line-height: 7.5rem;
  font-weight: 500;
  position: relative;
  padding: 0 9rem;
  background-image: url(../images/icons/leftIcon.svg),
    url(../images/icons/rightIcon.svg);
  background-position: left center, right center;
  background-repeat: no-repeat;
  margin: 6.5rem 0 2rem;
}

.form-control:focus {
  box-shadow: none;
}

.roomPrice {
  text-align: right;

  h4 {
    font-size: 1.2rem;
    font-weight: 500;
    color: #9f9f9f;
  }

  h5 {
    font-size: 2.4rem;
    font-weight: 700;
    color: #5f2146;
    margin: 0;

    span {
      font-size: 1.4rem;
    }
  }

  p {
    font-size: 1.2rem;
    font-weight: 500;
    color: #484848;
  }
}

.roomReview {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
  margin: 1.4rem 0 0;

  .ratingPoint {
    border: 0.1rem solid #ad926f;
    border-radius: 0.4rem;
    padding: 0.8rem 1rem;
    margin-right: 0.5rem;
    width: 4rem;
    text-align: center;
  }

  span {
    font-weight: 700;
    color: #279051;
    margin-right: 0.5rem;
  }
}

.roomCard {
  background-color: #fff;
  background-image: url("../../styles/images/artwork-half.svg");
  background-position: right center;
  background-repeat: no-repeat;
  margin: 0 0 3rem 0;
  border-radius: 1.2rem 0 0 1.2rem;
  overflow: hidden;

  .roomImage {
    width: 30rem;
    height: 28.5rem;
    border-radius: 1.5rem 0 0 1.5rem;
  }
  .roomImageMob {
    display: none;
  }
  .roomContentWrapper {
    margin: 2.2rem;

    h3 {
      font-size: 2rem;
      line-height: 3rem;
      font-weight: 700;
    }

    .bedType {
      color: #9f9f9f;
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-weight: 500;

      .bedImage {
        vertical-align: middle;
        margin: 0 0.2rem 0 0;
      }
    }
    .roomsAvailability {
      color: #9f9f9f;
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-weight: 600;
    }
    .roomSpecs {
      .capacitySpec {
        .personImg {
          margin: 0 0.5rem 0 0;
        }

        .infantImg {
          margin: 0 0.5rem;
        }
      }

      span {
        font-size: 1.2rem;
        line-height: 1.8rem;
        font-weight: 500;
        margin-right: 3rem;
      }
    }

    .bottomBtns {
      border-top: 0.1rem solid rgba(17, 34, 17, 0.25);
      margin-top: 2.3rem;
      padding-top: 2.3rem;
      .bookBtn {
        min-width: 20rem;
      }
      .viewDetail {
        margin: 0 2.4rem 0 0;
      }

      .counter {
        .btn-outline-primary {
          width: 5.6rem;
          height: 5.6rem;
          min-width: 0;
          font-size: 3rem;
          font-weight: normal;
        }

        .form-control {
          margin: 0 0.6rem;
          text-align: center;
          color: #000000;
          font-size: 1.6rem;
          width: 5.6rem;
          height: 5.6rem;
          border: 0.1rem solid #d2d1d3;
        }
      }
    }
  }
}

.breadcrumb {
  margin: 1.5rem 0 2.6rem;

  .breadcrumb-item {
    font-size: 1.4rem;

    a {
      color: #ad926f;
      text-decoration: none;
    }
    & + .breadcrumb-item {
      &:before {
        content: ">";
        padding: 0 1.2rem;
        color: #ad926f;
      }
    }
  }
}

.btn-delete {
  width: 2rem;
  height: 2rem;
  background-image: url("../images/icons/deleteIcon.svg");
  background-size: contain;
  text-indent: -9999px;
  background-repeat: no-repeat;
  background-position: center;
}

.list-none {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tncList {
  margin: 0 0 2.5rem 0;
  li {
    padding: 0.7rem 0;
    font-size: 1.4rem;
  }
}

.rulesList {
  li {
    display: flex;
    border-bottom: 0.1rem solid #d9d9d980;
    padding: 1.3rem 0;

    .iconLegends {
      min-width: 25rem;
    }

    &:last-child {
      border: none;
    }

    .ruleText {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }
}

.bookingDetailBox {
  padding: 0 2rem 0 4.8rem;
  margin: 0 0 0 4.8rem;
  border-left: 0.1rem solid #d7e2ee;
  .roomNum,
  .guestNum {
    margin-top: 3rem;
  }
}
.bookingInfo {
  padding: 0 0 0 4rem;
  background-repeat: no-repeat;
  // background-position: left center;
  line-height: 2rem;
  h6 {
    font-size: 1.2rem;
    font-weight: 600;
    opacity: 0.6;
    color: #112211;
  }

  p {
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0;
    color: #112211;
  }
}

.clockIcon {
  background-image: url("../images/icons/clockSquare.svg");
}
.cotIcon {
  background-image: url("../images/icons/cotSquare.svg");
}
.guestIcon {
  background-image: url("../images/icons/guestSquare.svg");
}

.shareBtn {
  width: 4rem;
  height: 4rem;
  background-image: url("../images/icons/shareIcon.svg");
  background-size: contain;
  text-indent: -9999px;
  border-radius: 0;
  border: 0;
  background-repeat: no-repeat;
  background-position: center center;

  &:hover {
    background-image: url("../images/icons/shareIconHover.svg");
  }

  &:focus {
    background-image: url("../images/icons/shareIconFocus.svg");
    border: 0;
    box-shadow: none;
  }
}

.layered {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #7c6a4680;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.aboutContainer {
  height: 80vh;
  position: relative;
  z-index: 2;
  background-image: url("../../styles/images/background.webp");
  background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center center;
  .heroContent {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    h1 {
      font-size: 6rem;
      color: #fff;
      font-weight: 800;
      margin: 0 0 4rem 0;
    }
    p {
      font-size: 2rem;
      line-height: 3rem;
      color: #fff;
      font-weight: 600;
      margin: 0;
    }
  }
}

.heroContainer {
  height: 80vh;
  position: relative;
  z-index: 2;
  background-image: url("../../styles/images/office.webp");
  background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center center;
  .heroContent {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    h1 {
      font-size: 6rem;
      color: #fff;
      font-weight: 800;
      margin: 0 0 4rem 0;
    }
    p {
      font-size: 2rem;
      line-height: 3rem;
      color: #fff;
      font-weight: 600;
      margin: 0;
    }
  }
}

.bgGraphic {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  .bgGraphicContainer {
    height: 100%;
    background-image: url("../images/bgGraphic.svg");
    background-position: right bottom;
    background-repeat: no-repeat;
    text-indent: -9999px;
  }
}

.switchControl {
  .form-switch {
    padding: 0;
    .form-check-input {
      width: 6.6rem;
      height: 3.4rem;
      cursor: pointer;
      margin: 0;
      background-image: url("../../styles/images/icons/switchOff.svg");
      background-color: #d9d9d980;

      &:checked {
        background-image: url("../../styles/images/icons/switchOn.svg");
        border: 0;
      }

      &:focus {
        box-shadow: none;
        border: 0;
      }
    }
  }
  .switchLabel {
    color: #112211;
    opacity: 0.4;
    font-size: 1.4rem;
    margin: 0 4rem 0 0;
  }
}

.slick-slider {
  padding-bottom: 4rem;
  .slick-arrow {
    top: 100%;
    &.slick-prev {
      left: 50%;
      margin-left: -4rem;
    }

    &.slick-next {
      right: 50%;
      margin-right: -4rem;
    }
    &:before {
      color: #112211;
      opacity: 0.8;
    }
  }
}
.react-datepicker-popper,
.react-datepicker__portal {
  box-shadow: 0px 2px 3px #0000000d;
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    line-height: 1;
  }
  h2.react-datepicker__current-month {
    font-size: 2rem;
    color: #1d2634;
  }
  .react-datepicker__header {
    background-color: #fff;
  }
  .react-datepicker__navigation-icon::before {
    border-color: #1d2634;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 4.2rem;
    height: 4.2rem;
    border-radius: 50%;
    font-size: 1.4rem;
    padding: 1rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: 0.1s all ease-in-out;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: #5f2146;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background-color: #5f2146;
    color: #fff;
    border-radius: 50%;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 50%;
  }

  .react-datepicker__day-name {
    color: #bebebe;
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    fill: #fff;
    color: #fff;
  }
}

.error {
  input {
    border: 0.2rem solid #fd736e;
  }

  .errorMsg {
    color: #fd736e;
    font-size: 1.4rem;
  }
}

.roomTypeModal {
  .modal-body{
    height: 70vh;
    padding: 1rem;
    .bookingPage {
      .finalBooking{
        .bookingBox {
          .middleBox {
            padding: 2rem 2.4rem;  
             .bookingDetailBox{
               margin: 0;
               border: none;
               padding: 0;
             }
             }
        }
      } 
    } 

  }
}
.modal-backdrop{z-index: 650000;}
.modal{z-index: 700000;}
.amenitiesModal{
  .iconLegends{
    display: block;
    padding: 0 0 2.5rem 0;
  }
}

/**Responsive css**/
@media (768px <= width < 990px) {
}

/**Mobile**/
@media (width < 768px) {
  .container-lg{padding-left: 1.5rem;padding-right: 1.5rem;}
  .hide-mobile {
    display: none !important;
  }
  .show-mobile {
    display: block !important;
  }
  .roomPrice {
    h5 {
      font-size: 2rem;
      span {
        font-size: 1.2rem;
      }
    }
  }
  .fieldContainer {
    padding: 2rem 1rem;
    margin: 2rem 0 4rem;
    text-align: center;
    .formField {
      margin: 0 0 2rem 0;

      &.dateField {
        position: relative;
        z-index: 5;
      }
      
      &.chkInDate {
        position: relative;
        z-index: 10;
      }
    }
    .btn {
      width: 100%;
      padding: 1.5rem 1rem;
    }
  }
  .iconHeading {
    font-size: 2rem;
    background-size: 25px 28px;
    line-height: 3rem;
    margin: 3rem 0 2rem 0;
    padding: 0 5rem;
  }
  .btn-outline-primary {
    padding: 1rem;
    font-size: 1.4rem;
  }
  .btn-primary {
    padding: 1rem;
    font-size: 1.4rem;
  }
  .btn-favorite {
    width: 4rem;
    height: 4rem;
  }
  .roomCard {
    padding: 1.2rem;
    background-position: right top;
    .roomImage {
      display: none;
    }
    .roomContentWrapper {
      margin: 0;

      .roomImageMob {
        display: block;
        width: 9rem;
        height: 9rem;
        border-radius: 1.2rem;
      }
      h3 {
        font-size: 1.4rem;
        line-height: 2.1rem;
      }
      .roomDetail {
        margin-left: 1.2rem;
      }
      .roomPrice {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 2.4rem;
        text-align: left;
        p {
          margin: 0;
        }
      }
      .bottomBtns {
        margin-top: 2rem;
        padding-top: 2rem;
        flex-wrap: wrap;
        .viewDetail {
          padding: 0.8rem 0.5rem;
          margin: 0 0.5rem 0 0;
        }
        .counter {
          margin-left: auto;
          .form-control {
            width: 4rem;
            height: 4rem;
            padding: 0.5rem;
          }
          .btn-outline-primary {
            width: 4rem;
            height: 4rem;
          }
        }
        .bookBtn {
          min-width: 0;
          flex: 1 0 100%;
          margin-top: 1rem;
        }
      }
    }

    .amenitiesBtn {
      display: block;
      color: #4499ee;
      text-decoration: none;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .bookingInfo {
    h6 {
      font-size: 1.2rem;
    }
    p {
      font-size: 1.6rem;
    }
  }

  .switchControl {
    margin-top: 1rem;
    .switchLabel {
      font-size: 1.2rem;
      margin-right: 1rem;
    }

    .form-switch {
      .form-check-input {
        width: 5rem;
        height: 2.5rem;
      }
    }
  }

  .tncList {
    li {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }

  .rulesList {
    li {
      display: block;
      .iconLegends {
        max-width: 100%;
      }

      .ruleText {
        margin-top: 1rem;
      }
    }
  }

  .bookingDetailBox {
    border: 0;
    padding: 0;
    margin: 0;
  }

  .divider {
    margin: 2rem 0;
  }

  .react-datepicker-popper,
  .react-datepicker__portal {
    h2.react-datepicker__current-month {
      font-size: 1.4rem;
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 3rem;
      height: 3rem;
      font-size: 1.2rem;
      padding: 1rem 0.5rem;
    }
  }
  .totalAvailable {
    p {
      font-size: 1.2rem;
    }
  }

  .aboutContainer {
    height: 22.5rem;
    img {
      height: 100%;
      object-fit: cover;
    }
    .heroContent {
      h1 {
        font-size: 1.6rem;
        margin-bottom: 1.5rem;
      }
      p {
        font-size: 1.2rem;
        line-height:1.8rem;
      }
    }
  }

  .heroContainer {
    height: 22.5rem;
    img {
      height: 100%;
      object-fit: cover;
    }
    .heroContent {
      h1 {
        font-size: 1.6rem;
        margin-bottom: 1.5rem;
      }
      p {
        font-size: 1.2rem;
        line-height:1.8rem;
      }
    }
  }

  .bgGraphic {
    .bgGraphicContainer {
      background-position: center 108%;
      background-size: 90% auto;
    }
  }
  .roomTypeModal {
    .modal-body{
      padding: 1rem 0;


    }
  }
}
/**Responsive css ends**/
/*Receipt CSS start*/
.printReceipt{
  .container {
    width: 80rem;
  }
  table{
    tr{
      th{
        padding: 0.5rem 1rem;
      }
      td{
        font-size: 1.4rem;
        padding: 0.5rem 1rem;
        &.heading{font-weight: 700;font-size: 1.6rem;}
        &.subHeading{font-weight: 700;font-size: 1.4rem;}
      }
    }
    .tableBox{
      border: 1px solid #ccc;
      padding: 1rem;
    }
  }
 
  
}
@media print {
  body { width: 280mm }
 header,footer,.bottomMenu{display: none !important;} 
 .printReceipt {
  padding-top: 2rem;
  .container{width: 100%;}
  table {  

    tr {
      th.pageHeading{font-size: 2rem;}
    td{font-size: 2rem; padding: 1rem;}
   
  } }


 }
 
}
/*Receipt CSS ends*/

@media (width > 990px) {
  .container, .container-lg, .container-md, .container-sm{
    max-width: 100%;
    padding-left: 1.5rem;padding-right: 1.5rem;
  }
}
@media (width >= 120px) {
  .container, .container-lg, .container-md, .container-sm{
    max-width: 1140px;
    padding-left: 1.5rem;padding-right: 1.5rem;
  }
}