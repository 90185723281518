
/**Mobile**/
@media ( width < 768px) {
    .bottomMenu{
        position: fixed;
        bottom: 0;
        left: 0;
        height: 6.5rem;
        background-color: #fff;
        width: 100%;
        border-top: 0.1rem solid #ad926f;
z-index: 400000;
.nav-item{
    height: 100%;
    .nav-link{
        height: 100%;
        padding: 0 2.4rem;
        color: #00000099;
        font-size: 1.2rem;
        height: 100%;
        padding: 0.5rem 1.2rem;
font-weight: 500;
min-width: 10rem;
        span{
            margin-top: 0.5rem;
        }

        &.active{
            color: #7C6A46;
        }
    }
}
      

    }
}
/**Responsive css ends**/