header {
    
  .navbar {
    background-color: #fff;
    padding: 1.5rem 0;
    .nav-link {
      color: #112211;
      font-size: 1.6rem;
      line-height: 2.3rem;
      margin: 0 3rem;
      font-weight: 700;

      &:hover {
        color: #ad926f;
      }
      &.active {
        color: #ad926f;
      }
    }
  }

  .favorites {
    background-image: url(../../styles/images/icons/heart.svg);
    background-position: left center;
    padding: 0 0 0 3.5rem;
    background-size: 2.1rem 1.8rem;
    background-repeat: no-repeat;

    .nav-link {
      margin: 0;
    }
  }

  .dropdown {
    margin-left: 2.4rem;
    position: relative;
    .btn-primary {
      background: none;
      font-size: 0;
      line-height: 0;
      padding: 0;
      min-width: 0;

      &:hover,&:focus,&:active {
        background: transparent !important;
        border: 0 !important;
      }


    }
    img {
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 50%;
    }

    .dropdown-toggle {
      &:after {
        position: absolute;
        z-index: 1;
        width: 1.2rem;
        height: 1.2rem;
        background-image: url(../../styles/images/icons/bottomArrow.svg);
        background-size: cover;
        bottom: 0;
        right: -0.2rem;
      }
    }

    .dropdown-menu {
      min-width: 20rem;
      box-shadow: 0px 3px 6px #00000012;
      border: 0.1rem solid #d9d9d9;
      padding: 0;
      .dropdown-item {
        padding: 2rem 1rem;
        font-size: 1.5rem;
        line-height: 2.3rem;
        text-align: center;
        font-weight: 500;
        color: #112211;
        transition: all 0.2s ease-in-out;
        &:first-child {
          border-bottom: 0.1rem solid #D9D9D9;
          background-color: transparent !important;
          color: #112211 !important;
        }

        &.active, &:hover{
          background-color: #5f2146;
          color: #fff;
        }
      }
    }
  }

  .hamburger {
    display: none;
  }

  .menuOverlay {
    width: 0;
    height: 0;
    position: fixed;
    top: 0;
    left: 0;
    background-color:#ad926f;
    opacity: 0;
    z-index: 500000;
    //transition: opacity 0.3s ease-in-out;
    -webkit-transition: -webkit-opacity .5s ease-in-out;
    transition: -webkit-opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out, -webkit-opacity .5s ease-in-out;
    -webkit-transition-delay: .5s;
    transition-delay: .5s;
  }
}

/**Responsive css**/
@media (768px <= width < 990px) {
  header {
    .navbar {
      .nav-link {
        margin: 0 1rem;
        font-size: 1.2rem;
      }
    }
    .navbar-brand {
      width: 8rem;
      height: 5rem;
    }

    .dropdown {
      margin-left: 1rem;
    }
    .favorites {
      padding-left: 1.5rem;
    }
  }
}

/**Mobile**/
@media (width < 768px) {

  header {
    height: 6.4rem;
    position: relative;
    width: 100%;
    background-color: #fff;
    z-index: 600000;
    border-bottom: 0.1rem solid #ad926f;
    .hamburger {
      display: block;
      position: relative;
      top: 0;
      left: 0;
      height: 40px;
      width: 30px; 
      display: flex;
      align-items: center;
      justify-content: center;

      & > div {
        position: relative;
        top: 0;
        left: 0;
        background: #353840;
        height: 2px;
        width: 100%;
        transition: all 0.4s ease;

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: -10px;
          background: #353840;
          width: 100%;
          height: 2px;
          transition: all 0.4s ease;
        }

        &::after {
          top: 10px;
        }
      }     
    }

    .navbar-brand {
      // width: 5.6rem;
      // height: 3rem;
        width: 7rem;
      height: 4rem;
    }
    .navbar-expand {
      &.navbar {
        padding: 1.2rem 0;
      }
      .navbar-nav {
        position: fixed;
        flex-direction: column;
        width: 20rem;
        background-color: #fff;
        top: 0;
        left: 0;
        z-index: 600000;
        border: 0.1rem solid #d9d9d9;
        box-shadow: 0px 3px 6px #00000012;
        transition: all 0.3s ease-in-out;
        padding-top: 6rem;
        -webkit-transition: -webkit-transform .5s ease-in-out;
        transition: -webkit-transform .5s ease-in-out;
        transition: transform .5s ease-in-out;
        transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
        // -webkit-transition-delay: .5s;
        // transition-delay: .5s;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        height: 100%;
        .nav-link {
          margin: 0;
          display: block;
          text-align: center;
          padding: 2rem 1rem;

          &.active{
            background-color:#ad926f;
            color: #fff;
          }
        }

        .hamburger{
          top: -4.5rem;
          left: 1.5rem;
        }
      }
    }
    .favorites {
      display: none;
    }
    .dropdown{
      margin: 0;

      .dropdown-item{
        white-space: normal;
      }
    }
  }

  .menuOpen {
   
    .hamburger {
        & > div {
          background-color: transparent;

          &::before {
            top: 0;
            transform: rotate(45deg);
          }

          &::after {
            top: 0;
            transform: rotate(135deg);
          }
        }       
    }
    .menuOverlay {
     width: 100%;
     height: 100%;
      opacity: 0.5;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
    }
    header {
      .navbar-expand {
        .navbar-nav {
          webkit-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
        }      
        }
    }

  }

  html.lenis body.menuOpen{ overflow-y: hidden;}
}
/**Responsive css ends**/
